<template>
  <v-card>
    <v-toolbar flat v-if="!$vuetify.breakpoint.xs">
      <DateInput
        label="von"
        v-model="startDate"
        hideDetails
        class="mr-2"
        clearable
      ></DateInput>
      <DateInput
        label="bis"
        v-model="endDate"
        hideDetails
        class="mx-2"
        clearable
      ></DateInput>
      <v-btn
        class="mr-2"
        @click="
          startDate = '';
          endDate = '';
        "
        text
        >alle</v-btn
      >
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-switch
              inset
              hide-details
              label="Anlass"
              v-model="event"
            ></v-switch>
          </div>
        </template>
        <span>nach Anlass gruppiert und sortiert</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="ma-0 text-right">
      <template v-if="type == 'cantonal'">
        <span class="mr-2">Beitrag Kanton</span>
        <v-chip light
          ><CurrencyValue :value="overview.balanceCantonal" />
        </v-chip>
      </template>
      <template v-else>
        <span class="mr-2">Eigene Kosten</span>
        <v-chip light>
          <CurrencyValue light :value="overview.balanceOwn" />
        </v-chip>
      </template>
    </v-card-text>
    <v-card-text class="ma-0 pa-0" v-if="!$vuetify.breakpoint.xs">
      <FinancePersonTableGrouped
        v-if="event"
        :items="items"
      ></FinancePersonTableGrouped>
      <FinancePersonTableSimple
        v-else
        :items="items"
        hideBalance
      ></FinancePersonTableSimple>
    </v-card-text>
    <v-card-text class="ma-0 pa-0" v-else>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.id"
          :three-line="!!item.number"
        >
          <v-list-item-avatar
            ><PortraitImage :value="item.creator"
          /></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>
              <DateValue :value="item.date" />
            </v-list-item-subtitle>
            <v-list-item-title
              ><strong>{{ item.event ? item.event : "" }}</strong>
              {{ item.description }}</v-list-item-title
            >

            {{ item.number ? item.number : "" }}
          </v-list-item-content>
          <v-chip><CurrencyValue :value="item.amount" /></v-chip>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "common/components/CurrencyValue.vue";
import DateInput from "common/components/DateInput.vue";
import DateValue from "common/components/DateValue.vue";
import FinancePersonTableSimple from "common/components/Finance/FinancePersonTableSimple.vue";
import FinancePersonTableGrouped from "common/components/Finance/FinancePersonTableGrouped.vue";
import PersonItem from "common/components/PersonItem.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import { today } from "common/utils/date.js";
export default defineComponent({
  components: {
    CurrencyValue,
    DateInput,
    DateValue,
    FinancePersonTableSimple,
    FinancePersonTableGrouped,
    PersonItem,
    PortraitImage,
  },
  props: ["type"],
  data() {
    return {
      event: false,
      startDate: "",
      endDate: "",
      loading: false,
      balance: 0,
      items: [],
      transactions: [],
      overview: {},
    };
  },
  watch: {
    event() {
      if (this.event) {
        localStorage.setItem("financeGroupedEvents", true);
      } else {
        localStorage.removeItem("financeGroupedEvents");
      }
    },
    startDate() {
      this.fetchData();
    },
    endDate() {
      this.fetchData();
    },
    type() {
      this.fetchData();
    },
  },
  methods: {
    today,
    async fetchData() {
      if (!this.type) {
        return;
      }
      this.overview = await this.apiGet({
        resource: "finance/personoverview",
        id: this.$_profilePerson.id,
      });
      this.items = await this.apiList({
        resource: "finance/persontransaction",
        query: `person=${this.$_profilePerson.id}&type=${this.type}&startDate=${this.startDate}&endDate=${this.endDate}`,
      });
      this.balance =
        this.items.length == 0
          ? 0.0
          : this.items[this.items.length - 1].balance;
      this.transactions = this.items.reverse();
    },
  },
  mounted() {
    this.event = localStorage.getItem("financeGroupedEvents");
    this.fetchData();
  },
});
</script>
